import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'match-company-overview',
  templateUrl: './company-overview.component.html',
  styleUrls: ['./company-overview.component.scss']
})
export class CompanyOverviewComponent implements OnInit {
  @Input('match') match: any;

  constructor() {}

  ngOnInit(): void {}
}
