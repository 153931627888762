<div class="row">
  <ng-container
    *ngFor="
      let image_png of match.company.gallery.png;
      let image_webp;
      of: match.company.gallery.webp
    "
  >
    <mat-card class="col">
      <!-- <img src="{{image}}" style="height: 100px; width: 100px" /> -->
      <picture>
        <source srcset="{{image_webp}}" type="image/webp" />
        <source srcset="{{image_png}}" type="image/png" />
        <img src="{{ image_png }}" style="width: 100%" alt="gallery" />
      </picture>
    </mat-card>
  </ng-container>
</div>
