import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatchesComponent } from './components/matches/matches.component';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatchDetailComponent } from './components/match-detail/match-detail.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AbbreviationsPipe } from './pipes/abbreviations.pipe';
import { OverviewComponent } from './components/match-detail/templates/overview/overview.component';
import { SalaryComponent } from './components/match-detail/templates/salary/salary.component';
import { CompanyDescriptionComponent } from './components/match-detail/templates/company-description/company-description.component';
import { CompanyBenefitsComponent } from './components/match-detail/templates/company-benefits/company-benefits.component';
import { CompanyOverviewComponent } from './components/match-detail/templates/company-overview/company-overview.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { CompanyLogoComponent } from './components/common/company-logo/company-logo.component';
import { ArrayAbbreviationPipe } from './pipes/array-abbreviation.pipe';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ShorterComponent } from './components/common/shorter/shorter.component';
import { RequirementsComponent } from './components/match-detail/templates/requirements/requirements.component';
import { GalleryComponent } from './components/match-detail/templates/gallery/gallery.component';
import { BottomSheetComponent } from './components/matches/bottom-sheet/bottom-sheet.component';
import { SuccessComponent } from './components/success/success.component';
import { ErrorComponent } from './components/error/error.component';
import { TutorialComponent } from './components/matches/tutorial/tutorial.component';

@NgModule({
  declarations: [
    AppComponent,
    MatchesComponent,
    MatchDetailComponent,
    AbbreviationsPipe,
    ArrayAbbreviationPipe,
    OverviewComponent,
    SalaryComponent,
    CompanyDescriptionComponent,
    CompanyBenefitsComponent,
    CompanyOverviewComponent,
    CompanyLogoComponent,
    NotFoundComponent,
    ShorterComponent,
    RequirementsComponent,
    GalleryComponent,
    BottomSheetComponent,
    SuccessComponent,
    ErrorComponent,
    TutorialComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatDividerModule,
    MatBottomSheetModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse' })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
