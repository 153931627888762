<ng-container *ngIf="!loading$">
  <header>
    <company-logo [companyDetails]="match.company" [size]="150" [shadow]="true"></company-logo>
    <h3 class="details-title" *ngIf="match?.job?.title">{{ match.job.title }}</h3>
    <p class="details-postcode">
      <mat-icon>signpost</mat-icon>{{ match.job.postcode }} {{ match.job.city }} |
      <mat-icon>room</mat-icon>{{ match.distance | number: '1.1-1' }}&#8202;km |
      <mat-icon>departure_board</mat-icon>
      {{ match.duration * 1000 - 3600 * 1000 | date: 'H:mm' }}&#8202;min
    </p>
  </header>
  <div class="content">
    <match-overview [match]="match" [applier]="applier"></match-overview>
    <match-requirements [match]="match" [applier]="applier"></match-requirements>
    <match-salary [match]="match"></match-salary>
    <match-company-overview [match]="match"></match-company-overview>
    <match-company-description [match]="match"></match-company-description>
    <match-company-benefits [match]="match"></match-company-benefits>
    <match-gallery [match]="match"></match-gallery>
  </div>
  <footer class="row">
    <div class="col right">
      <div class="send_button">
        <button mat-button (click)="close()"><mat-icon>arrow_back</mat-icon>ÜBERSICHT</button>
        <button mat-button (click)="toggleClose(match)">
          <ng-container *ngIf="!selectionService.isSelected(match)">
            <mat-icon>check</mat-icon>AUSWÄHLEN
          </ng-container>
          <ng-container *ngIf="selectionService.isSelected(match)">
            <mat-icon>close</mat-icon>ABWÄHLEN
          </ng-container>
        </button>
      </div>
    </div>
  </footer>
</ng-container>
<ng-container *ngIf="loading$">
  <div class="content">
    <ngx-skeleton-loader
      count="5"
      appearance="line"
      [theme]="{ 'height.px': 75 }"
    ></ngx-skeleton-loader>
  </div>
</ng-container>
