import shiftHoursData from './abbreviations/shiftHoursType.json';
import careTypeData from './abbreviations/careType.json';
import educationTypeData from './abbreviations/educationType.json';
import workingScopeTypeData from './abbreviations/scopeType.json';
import continuingEducationType from './abbreviations/continuingEducationType.json';
import specialRequirementType from './abbreviations/specialRequirementType.json';

export interface abbreviation {
  id: number;
  icon?: string;
  name?: string;
  fields: AbbreviationFields;
}

export interface AbbreviationFields {
  short_name: string;
  full_name: string;
}

export interface SelectList {
  item_id: number;
  item_text: string;
}

export type AbbreviationTypes =
  | 'shiftHours'
  | 'careType'
  | 'education'
  | 'workingScope'
  | 'continuingEducation'
  | 'specialRequirement';

export class AbbreviationModel {
  public getList(type: AbbreviationTypes): abbreviation[] {
    let data: abbreviation[] = [];
    if (type == 'shiftHours') {
      data = shiftHoursData;
    } else if (type == 'careType') {
      data = careTypeData;
    } else if (type == 'education') {
      data = educationTypeData;
    } else if (type == 'workingScope') {
      data = workingScopeTypeData;
    } else if (type == 'continuingEducation') {
      data = continuingEducationType;
    } else if (type == 'specialRequirement') {
      data = specialRequirementType;
    }
    return data;
  }

  public getById(id: any, type: AbbreviationTypes, field = 'full_name'): string {
    let data: abbreviation[] = this.getList(type);
    const el = data.find((val) => val.id == id);
    if (!el) return '';
    if (field == 'short') return el.fields.short_name;
    if (field == 'icon') return el?.icon ?? '';
    return el.fields.full_name;
  }

  public getSelectList(type: AbbreviationTypes, notShow: number[] = []): SelectList[] {
    let list = this.getList(type);
    let returnList: SelectList[] = [];
    for (let item of list) {
      let obj: SelectList = {
        item_id: item.id,
        item_text: item.fields.short_name
      };
      if (!notShow.length || !notShow.includes(item.id)) {
        returnList.push(obj);
      }
    }
    return returnList;
  }
}
