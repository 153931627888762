<div style="max-width: 480px; margin: auto; padding: 15px 0px">
  <mat-list>
    <p>Du hast folgende Jobs ausgewählt:</p>
    <mat-list-item *ngFor="let match of selectionService.selected">
      {{ match.job.care_type | arrayAbbreviation: 'careType':' / ':'short_name' }} in
      {{ match.job.postcode }} {{ match.job.city }} ({{ match.company.name }})
    </mat-list-item>
  </mat-list>
  <p>
    Mit dem "Absenden" erhalten die zuständigen PDLs der Einrichtungen deine Kontaktdaten, denn Sie
    würden DICH gerne zum Kennenlernen einladen.
  </p>
  <div>
    <button mat-flat-button color="primary" [disabled]="buttonDisabled" (click)="send($event)">
      <mat-spinner *ngIf="buttonDisabled" [diameter]="25" [strokeWidth]="4"></mat-spinner>ABSENDEN
    </button>
  </div>
</div>
