<picture *ngIf="hasLogo">
  <source
    *ngIf="companyDetails?.logo?.webp?.length"
    srcset="{{ companyDetails?.logo.webp[0] }}"
    type="image/webp"
  />
  <source
    *ngIf="companyDetails?.logo?.png?.length"
    srcset="{{ companyDetails?.logo.png[0]  }}"
    type="image/png"
  />
  <img
    *ngIf="companyDetails?.logo?.png?.length"
    width="{{ size }}"
    [class.shadow]="shadow"
    border="{{ border }}"
    [class.circle]="circle"
    src="{{ companyDetails?.logo.png[0] }}"
    alt="{{ companyDetails?.name }} logo"
  />
</picture>
<h1 class="company-name" *ngIf="showLogo">
  {{ companyDetails?.name ?? '' }}
</h1>
