import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor() {}

  public intersect(a: any[], b: any[]) {
    var setB = new Set(b);
    var setC = [...new Set(a)].filter((x) => setB.has(x));
    if (setC.length != 0) {
      return setC;
    }
    return [a[0]];
  }
}
