<mat-card>
  <mat-card-subtitle> Überlick </mat-card-subtitle>
  <mat-list>
    <mat-list-item class="row">
      <div class="col">Versorgungsart</div>
      <div class="col">
        {{
          common.intersect(match.job.care_type, applier.caretype) | arrayAbbreviation: 'careType'
        }}
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="row">
      <div class="col">Schichten</div>
      <ng-container *ngIf="match?.job?.shift?.length; then shift; else shiftOther"> </ng-container>
      <ng-template #shift>
        <div class="col">
          {{ match?.job?.shift | arrayAbbreviation: 'shiftHours' }}
        </div>
      </ng-template>
      <ng-template #shiftOther>
        <div class="col">
          {{ match?.job?.shift_other }}
        </div>
      </ng-template>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="row">
      <div class="col">Arbeitsumfang</div>
      <div class="col">
        {{ match.job.working_type | arrayAbbreviation: 'workingScope' }}
      </div>
    </mat-list-item>
  </mat-list>
</mat-card>
