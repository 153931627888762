import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'shorter',
  templateUrl: './shorter.component.html',
  styleUrls: ['./shorter.component.scss']
})
export class ShorterComponent implements OnInit {
  @Input('height') height: number = 125;
  collapsed: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
