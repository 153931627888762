import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';

@Injectable({
  providedIn: 'root'
})
export class SelectionService {
  selected: any[] = [];

  constructor() {}

  public select(item: any) {
    if (!this.includes(item)) {
      this.selected.push(item);
    }
  }

  public length() {
    if (this.selected.length) return this.selected.length;
    return 0;
  }

  public isSelected(item: any) {
    return this.includes(item);
  }

  public toggle(item: any) {
    if (this.includes(item)) {
      let afterDelete = this.selected.filter((ele) => ele.extid !== item.extid);
      this.selected.length = 0;
      afterDelete.forEach((element) => {
        this.selected.push(element);
      });
    } else {
      this.selected.push(item);
    }
  }

  // get all ids of selected matches
  public getMatchIds(): string[] {
    const ids: string[] = [];
    this.selected.forEach((match: any) => {
      ids.push(match.extid);
    });
    return ids;
  }

  private includes(item: any) {
    return this.selected.some((elem) => {
      return elem.extid === item.extid;
    });
  }
}
