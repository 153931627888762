import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'match-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: ['./requirements.component.scss']
})
export class RequirementsComponent implements OnInit {
  @Input('match') match: any;
  @Input('applier') applier: any;

  constructor(public common: CommonService) {}

  ngOnInit(): void {}
}
