<div class="content">
  <img src="assets/laenk-logo.png" class="logo" />
  <h2>Erfolgreich gesendet!</h2>
  <h1 class="icon">
    <button mat-fab color="primary">
      <mat-icon>check</mat-icon>
    </button>
  </h1>
  <h3>
    Die ausgewählten Unternehmen haben dein Bewerberprofil erhalten und werden sich innerhalb der
    nächsten 48h bei dir melden.
  </h3>
  <h3>Du hast noch Fragen, dann kontaktiere uns!</h3>

  <a mat-icon-button>
    <mat-icon>phone</mat-icon>
  </a>
  <a mat-icon-button>
    <mat-icon>whatsapp</mat-icon>
  </a>
</div>
