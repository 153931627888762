import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'match-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  @Input('match') match: any;

  constructor() {}

  ngOnInit(): void {}
}
