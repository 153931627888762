import { Component, OnInit } from '@angular/core';
import { SelectionService } from 'src/app/services/selection.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatchService } from 'src/app/services/match.service';
import { Router } from '@angular/router';

@Component({
  selector: 'bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent implements OnInit {
  buttonDisabled: boolean = false;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    public selectionService: SelectionService,
    private matchService: MatchService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  send(event: any): void {
    this.buttonDisabled = true;
    var oldText = event.target.lastChild.data;
    event.target.lastChild.data = '';
    var ids: string[] = this.selectionService.getMatchIds();
    this.matchService.sendMatches(ids).subscribe(
      (res: any) => {
        this.buttonDisabled = false;
        event.target.lastChild.data = oldText;
        this._bottomSheetRef.dismiss();
        this.router.navigateByUrl('/abgeschickt');
      },
      (err: any) => {
        this.router.navigateByUrl("'/error");
        this.buttonDisabled = false;
        this._bottomSheetRef.dismiss();
        event.target.lastChild.data = oldText;
      }
    );
  }
}
