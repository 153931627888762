import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

interface companyDetails {
  name: string;
  external_name: string | null;
  about: string;
  company_size: number;
  benefits: string;
  phone_number: number;
  website: number;
  logo: any;
  gallery: any;
}

@Component({
  selector: 'company-logo',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.scss']
})
export class CompanyLogoComponent implements OnInit {
  @Input('size') size: number = 50;
  @Input('shadow') shadow: boolean = false;
  @Input('border') border: number = 0;
  @Input('circle') circle: boolean = true;
  @Input('companyDetails') companyDetails: companyDetails | null = null;
  @Input('showLogo') showLogo: boolean = true;

  hasLogo: Boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.hasLogo = Boolean(Object.entries(this.companyDetails?.logo).length);
  }
}
