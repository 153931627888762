import { Component, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { SelectionService } from '../../services/selection.service';
import { MatchService } from '../../services/match.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { BottomSheetComponent } from './bottom-sheet/bottom-sheet.component';

@Component({
  selector: 'app-matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.scss']
})
export class MatchesComponent implements OnInit {
  selection = new SelectionModel(true);
  loading$ = false;
  aid: string = '';
  routeSub: any;
  data: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public selectionService: SelectionService,
    public matchService: MatchService,
    private _bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    this.loading$ = true;
    this.data = this.route.paramMap.subscribe((params: any) => {
      this.aid = params.get('aid');
      return this.matchService.getData(this.aid).subscribe({
        next: (response: any) => {
          this.data.length = 0;
          this.data = response;
          this.loading$ = false;
        },
        error: () => {
          this.router.navigateByUrl('/404');
        }
      });
    });
  }

  openBottomSheet(): void {
    this._bottomSheet.open(BottomSheetComponent);
  }
}
