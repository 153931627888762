<ng-container *ngIf="showTutorial">
  <div class="full" (click)="hideTutorial()">
    <div class="tutorial">
      <div class="circle"></div>
      <div class="content">
        <p>
          Du hast eine Job ausgewählt. Du kannst dich mit dem <mat-icon>send</mat-icon>-Button
          bewerben oder vorher noch weitere Jobs auswählen.
        </p>
      </div>
    </div>
  </div>
</ng-container>
