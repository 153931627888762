<mat-card>
  <mat-card-subtitle> Das wünschen wir uns von Ihnen </mat-card-subtitle>
  <mat-list>
    <mat-list-item class="row">
      <div class="col">Ausbildung</div>
      <div class="col">
        {{
          common.intersect(match.job.education_type, applier.education)
            | arrayAbbreviation: 'education'
        }}
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="row">
      <div class="col">Weiterbildungen</div>
      <div class="col">
        <ng-container *ngIf="match.job.continuing_education.length > 0">
          {{
            common.intersect(match.job.continuing_education, applier.continuing_education)
              | arrayAbbreviation: 'continuingEducation'
          }}
        </ng-container>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item class="row">
      <div class="col">zusätzl. Anforderungen</div>
      <div class="col">
        <ng-container *ngIf="match.job.special_requirements.length > 0">
          {{ match.job.special_requirements | arrayAbbreviation: 'specialRequirement' }}
        </ng-container>
        <ng-container *ngIf="match.job.special_requirements.length > 0">
          {{ match.job.special_requirements | arrayAbbreviation: 'specialRequirement' }}
        </ng-container>
      </div>
    </mat-list-item>
  </mat-list>
</mat-card>
