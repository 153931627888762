import { Pipe, PipeTransform } from '@angular/core';
import { AbbreviationModel, AbbreviationTypes } from '../models/abbreviation.model';

@Pipe({
  name: 'arrayAbbreviation'
})
export class ArrayAbbreviationPipe implements PipeTransform {
  transform(
    value: number[],
    type: AbbreviationTypes,
    split = ' / ',
    field: string = 'full_name'
  ): string {
    const convertedArray: string[] = [];
    (value || []).forEach((item) => {
      const value = new AbbreviationModel().getById(item, type, field);
      if (value) convertedArray.push(value);
    });
    return convertedArray.join(split);
  }
}
