<div class="content">
  <img src="assets/laenk-logo.png" class="logo" />
  <p class="intro">Klicke auf "interessiert mich", wenn du die Einrichtung kennenlernen willst</p>
  <ng-container *ngFor="let match of data.matches">
    <mat-card [class.selected]="selectionService.isSelected(match)">
      <mat-card-header class="mat-card-header-avatar-right">
        <company-logo
          mat-card-avatar
          [companyDetails]="match.company"
          [size]="75"
          [border]="0"
          [circle]="false"
          [showLogo]="false"
        ></company-logo>
        <mat-card-title>
          <p class="mat-card-job-title">
            {{ match?.company?.external_name || match?.company?.name }}
          </p>
          <p class="mat-card-company-name">{{ match.job.title }}</p>
        </mat-card-title>
        <mat-card-subtitle>
          <mat-icon>signpost</mat-icon>{{ match.job.postcode }} {{ match.job.city }}
          <mat-icon>room</mat-icon>{{ match.distance | number: '1.1-1' }}&#8202;km |
          <mat-icon>departure_board</mat-icon>
          {{ match.duration * 1000 - 3600 * 1000 | date: 'H:mm' }}&#8202;min
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p>
          <mat-icon>real_estate_agent</mat-icon>
          <span style="margin-left: 3px; line-height: 24px; vertical-align: top">{{
            match.job.care_type | arrayAbbreviation: 'careType'
          }}</span>
        </p>
        <p>
          <mat-icon>pending_actions</mat-icon>
          <ng-container *ngIf="match?.job?.shift?.length; then shift; else shiftOther">
          </ng-container>
          <ng-template #shift>
            <span style="margin-left: 3px; line-height: 24px; vertical-align: top">
              {{ match?.job?.shift | arrayAbbreviation: 'shiftHours' }}</span
            >
          </ng-template>

          <ng-template #shiftOther>
            <span style="margin-left: 3px; line-height: 24px; vertical-align: top">
              {{ match?.job?.shift_other }}</span
            >
          </ng-template>
        </p>
        <ng-container *ngIf="match.job.specification_care_type.includes(1)">
          In dieser Weaningeinheit werden beatmungspflichtige Patienten betreut und gepflegt.
        </ng-container>
        <ng-container *ngIf="match.job.specification_care_type.includes(2)">
          Diese Versorgung beinhaltet die Schulbegleitung unseres kleinen Patienten.
        </ng-container>
        <ng-container *ngIf="match.job.specification_care_type.includes(3)">
          Hier unterstützt du den kleinen Patienten außerdem als Begleitung im Kindergartenalltag.
        </ng-container>
        <ng-container *ngIf="match.job.specification_care_type.includes(4)">
          Der Schwerpunkt der Tätigkeit liegt in der Arbeit mit Menschen, die an Demenz erkrankt
          sind.
        </ng-container>
        <ng-container *ngIf="match.job.special_requirements.includes(2)">
          Für diese Stelle ist ein Führerschein der Klasse B notwendig.
        </ng-container>
        <ng-container *ngIf="match.job.special_requirements.includes(4)">
          Für diese Versorgung ist es notwendig die Grundqualifikation „Beatmungsschein“ zu haben.
        </ng-container>
        <ng-container *ngIf="match.job.special_requirements == 'Intensivpflegeerfahrung'">
          Hier ist erste Berufserfahrung in der außerklinischen Intensivpflege notwendig.
        </ng-container>
      </mat-card-content>
      <mat-card-actions align="start" class="row">
        <button mat-button class="col" (click)="selectionService.toggle(match)">
          <ng-container *ngIf="!selectionService.isSelected(match)">
            <mat-icon>thumb_up_off_alt</mat-icon>Interessiert mich
          </ng-container>
          <ng-container *ngIf="selectionService.isSelected(match)">
            <mat-icon>thumb_up</mat-icon>Interessiert mich
          </ng-container>
        </button>
        <button mat-button class="col" [routerLink]="'/' + aid + '/details/' + match.extid">
          Details
        </button>
      </mat-card-actions>
    </mat-card>
  </ng-container>
  <ng-container *ngIf="!loading$ && data.matches.length == 0">
    Es gibt keine Jobs, auf du dich dich bewerben kannst!
  </ng-container>
  <ng-container *ngIf="loading$">
    <ngx-skeleton-loader
      count="5"
      appearance="line"
      [theme]="{ 'height.px': 35 }"
    ></ngx-skeleton-loader>
  </ng-container>
</div>
<footer class="row">
  <div class="col right">
    <div class="row">
      <p class="col">{{ selectionService.length() }} Jobs ausgewählt</p>
      <div class="col send_button">
        <button
          mat-fab
          aria-label="Send Button"
          color="primary"
          [disabled]="!selectionService.selected.length"
          (click)="openBottomSheet()"
        >
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </div>
  </div>
</footer>
<app-tutorial [selectCount]="selectionService.selected.length"></app-tutorial>
