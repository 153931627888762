<div class="content">
  <img src="assets/laenk-logo.png" class="logo" />
  <h1>404</h1>
  <p>Dieser Link funktioniert nicht, bitte kontaktieren Sie uns!</p>
  <a mat-icon-button>
    <mat-icon>phone</mat-icon>
  </a>
  <a mat-icon-button>
    <mat-icon>whatsapp</mat-icon>
  </a>
</div>
