import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatchesComponent } from './components/matches/matches.component';
import { MatchDetailComponent } from './components/match-detail/match-detail.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SuccessComponent } from './components/success/success.component';
import { ErrorComponent } from './components/error/error.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '404'
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'abgeschickt',
    component: SuccessComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: ':aid',
    component: MatchesComponent
  },
  {
    path: ':aid/details/:mid',
    component: MatchDetailComponent
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
