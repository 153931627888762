import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnChanges } from '@angular/core';
import { timer } from 'rxjs';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnChanges {
  @Input('selectCount') selectCount: number | undefined;
  showTutorial: boolean = false;
  constructor(@Inject(DOCUMENT) private _document: Document) {}

  ngOnChanges(): void {
    if (localStorage.getItem('sendTutorial') != 'True') {
      if (this.selectCount == 1) {
        (document.querySelector('.umm-8v7a7a') as HTMLElement).style.zIndex = '-1';
        this.showTutorial = true;
        timer(10000).subscribe((val: number) => {
          this.hideTutorial();
        });
      }
    }
  }

  hideTutorial(): void {
    this.showTutorial = false;
    (document.querySelector('.umm-8v7a7a') as HTMLElement).style.zIndex = '1000000';
    localStorage.setItem('sendTutorial', 'True');
  }
}
