import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'match-salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.scss']
})
export class SalaryComponent implements OnInit {
  @Input('match') match: any;

  constructor() {}

  ngOnInit(): void {}
}
