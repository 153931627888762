import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MatchService {
  constructor(private http: HttpClient) {}

  getData(id: string | null) {
    return this.http.get(
      `${environment.BASE_URL}${environment.PATH}accounts/applier/public/?extID=${id}`
    );
  }

  getMatch(aid: string | null, mid: string | null) {
    this.getData(aid).subscribe((response: any) => {
      return {
        match: response.matches.filter((x: any) => {
          x.extid == mid;
        }),
        applier: response.applier
      };
    });
  }

  sendMatches(matchIds: string[]): Observable<any> {
    const body = {
      extID: matchIds,
      status: 5
    };
    return this.http
      .post<any>(`${environment.BASE_URL}${environment.PATH}match/matchlist/public/`, body)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
