<ng-container *ngIf="match.job.max_salary && match.job.min_salary">
  <mat-card>
    <mat-card-subtitle>
      <p>Gehalt</p>
      <a mat-icon-button>
        <mat-icon>tungsten</mat-icon>
      </a>
    </mat-card-subtitle>
    <mat-list-item>
      <div>
        <span>{{ match.job.min_salary }}€ </span>

        <span
          [style.display]="match.job.max_salary == match.job.min_salary ? 'none' : 'inline-block'"
        >
          - {{ match.job.max_salary }}€
        </span>
        Brutto pro Monat
      </div>
    </mat-list-item>
  </mat-card>
</ng-container>
