import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'match-company-benefits',
  templateUrl: './company-benefits.component.html',
  styleUrls: ['./company-benefits.component.scss']
})
export class CompanyBenefitsComponent implements OnInit {
  @Input('match') match: any;

  constructor() {}

  ngOnInit(): void {}
}
