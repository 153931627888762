<div #content [class.short]="!collapsed" [class.long]="collapsed" [style.max-height.px]="height">
  <ng-content></ng-content>
</div>

<div *ngIf="content.offsetHeight == height">
  <div class="button-row" [class.long]="collapsed">
    <button mat-mini-fab *ngIf="!collapsed" (click)="collapsed = !collapsed">
      <mat-icon>expand_more</mat-icon>
    </button>
    <button mat-mini-fab *ngIf="collapsed" (click)="collapsed = !collapsed">
      <mat-icon>expand_less</mat-icon>
    </button>
  </div>
</div>
