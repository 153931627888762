<ng-container *ngIf="match.company.company_size || match.company.about">
  <mat-card>
    <mat-card-subtitle> Überlick (Unternehmen) </mat-card-subtitle>
    <mat-list *ngIf="match.company.company_size">
      <mat-list-item class="row">
        <div class="col">Größe</div>
        <div class="col">
          {{ match.company.company_size }}
        </div>
      </mat-list-item>
      <mat-list-item *ngIf="match.company.about" class="row">
        {{ match.company.about }}
      </mat-list-item>
    </mat-list>
  </mat-card>
</ng-container>
