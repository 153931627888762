import { Component, OnInit } from '@angular/core';
import { SelectionService } from '../../services/selection.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MatchService } from '../../services/match.service';

@Component({
  selector: 'app-match-detail',
  templateUrl: './match-detail.component.html',
  styleUrls: ['./match-detail.component.scss']
})
export class MatchDetailComponent implements OnInit {
  match: any;
  applier: any;
  loading$ = false;
  mid: string = '';
  aid: string = '';

  constructor(
    private route: ActivatedRoute,
    public selectionService: SelectionService,
    private router: Router,
    public matchService: MatchService
  ) {}

  ngOnInit(): void {
    this.loading$ = true;
    this.route.paramMap.subscribe((params: any) => {
      this.aid = params.get('aid');
      this.mid = params.get('mid');
      this.matchService.getData(this.aid).subscribe({
        next: (response: any) => {
          this.applier = response.applier;
          response.matches.forEach((element: any) => {
            if (element.extid == this.mid) {
              this.match = element;
              this.loading$ = false;
            }
          });
        },
        error: () => {
          this.router.navigateByUrl('/404');
        }
      });
    });
  }

  toggleClose(element: any): void {
    this.selectionService.toggle(element);
    this.close();
  }

  close(): void {
    this.router.navigate(['/' + this.aid]);
  }
}
